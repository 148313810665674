import React from 'react'
import PropTypes from 'prop-types'

import { Billboard } from '@renderbus/common/components'
import { CaseContainer, CaseContent, CaseTitle, CaseReward } from './atom'

class CaseWrapper extends React.PureComponent {
  render() {
    const { title, rewards, info, background, bgMobile } = this.props
    let windowHeight = '100vh'
    if (typeof window !== 'undefined') {
      windowHeight = `${window.innerHeight}px`
    }
    return (
      <CaseContainer windowHeight={windowHeight}>
        <CaseContent>
          <CaseTitle>{title}</CaseTitle>
          {rewards &&
            rewards.map((reward, index) => {
              return <CaseReward key={index}>{reward}</CaseReward>
            })}
          <p>{info}</p>
        </CaseContent>
        <Billboard
          fluid={[
            bgMobile,
            {
              ...background,
              media: `(min-width:780px)`,
            },
          ]}
        />
      </CaseContainer>
    )
  }
}

CaseWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  rewards: PropTypes.arrayOf(PropTypes.string),
  info: PropTypes.string.isRequired,
  background: PropTypes.object,
}
export default CaseWrapper
