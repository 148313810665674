import styled from 'styled-components'
import { typography } from '@renderbus/common/theme'
import { H1, H2, Flex } from '@renderbus/common/components'
import Media from '@renderbus/common/theme/media'

export const BannerContainer = styled.div`
  position: relative;
  padding-top: 20vh;
  height: ${p => (p.windowHeight ? p.windowHeight : '100vh')};
  text-align: center;
  color: white;
`
export const BannerTitle = styled(H1)`
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.h2};
  }
`
export const BannerSubTitle = styled.p`
  font-size: ${typography.h4};
  margin: ${typography.text} auto ${typography.h1};
  font-weight: 300;
  ${Media.lessThan(Media.small)} {
    font-size: ${typography.text};
    margin: ${typography.text} auto;
  }
`
export const CompanyContainer = styled(Flex)`
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: auto;
  align-items: start;
  ${Media.lessThan(Media.small)} {
    margin: 0 20px;
  }
`
export const CompanyIcons = styled.img`
  margin-top: ${props => props.marginTop || '83px'};
  width: 45%;
  ${Media.lessThan(Media.small)} {
    width: 100%;
    margin-top: ${props => props.marginMobileTop || '44px'};
  }
`
export const CaseContainer = styled.div`
  position: relative;
  height: 100vh;
  color: white;
`
export const CaseContent = styled.div`
  position: absolute;
  top: 0;
  right: 20%;
  padding: 100px 20px 0;
  height: 50vh;
  width: 400px;
  background: rgba(0, 0, 0, 0.6);
`
export const CaseTitle = styled(H2)`
  margin-bottom: 30px;
`
export const CaseReward = styled.p`
  font-weight: 300;
  font-size: ${typography.textSmall};
  margin: 0;
`
export const CaseInfo = styled.p`
  font-weight: 300;
  font-size: ${typography.textSmall};
`
