import { Button, Flex, Footer, H2 } from '@renderbus/common/components'
import { EventBus } from '@renderbus/common/service'
import { color, hideSMDown, spacing, transition, typography } from '@renderbus/common/theme'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const CaseNav = styled(Flex)`
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: ${spacing.large};
  transform: translateY(-50%);
  max-height: 80%;
  background: rgba(34, 34, 34, 0.6);
  padding: ${spacing.small};
  border-radius: 50px;
  ${hideSMDown}
`
const MoreCaseContainer = styled.div`
  padding: 40px 0;
  background: ${color.primary};
  text-align: center;
  h2 {
    padding: ${spacing.large} 0;
  }
`
const CaseNavSpot = styled.div`
  margin: ${spacing.small} 0;
  width: ${typography.title};
  height: ${typography.title};
  color: ${p => (p.isActive ? color.primary : 'white')};
  border-radius: 50%;
  border: ${p => (p.isActive ? '2px solid' : 'none')};
  border-color: ${p => (p.isActive ? color.primary : 'grey')};
  background: transparent;
  cursor: pointer;
  transition: ${transition(['border-color', 'background-color'])};
  font-size: ${typography.text};
  text-align: center;
  line-height: ${typography.h2};
  font-weight: bold;
  :hover {
    color: ${color.primary};
    .case-nav-title {
      display: inline-block;
    }
  }
  position: relative;
`
const CaseNavTitle = styled.span`
  position: absolute;
  height: ${typography.h2};
  background: rgba(31, 196, 98, 0.8);
  border-radius: 15px;
  padding: 0 24px;
  line-height: ${typography.h2};
  left: 60px;
  top: 3px;
  color: white;
  white-space: nowrap;
  font-weight: 400;
  display: none;
`
const NavDirection = styled.div`
  margin-bottom: ${spacing.small};
  width: ${spacing.base};
  height: ${spacing.base};
  list-style: none;
  position: relative;
  svg {
    width: ${spacing.base};
    height: ${spacing.base};
    stroke: white;
    cursor: pointer;
    transition: ${transition('color')};
  }
  :hover {
    > svg {
      stroke: ${p => (p.disabled ? 'white' : color.primary)};
    }
  }
`

class FullPage extends React.Component {
  state = {
    activeSection: this.props.activeSection,
    windowHeight: 0,
    sideStart: 0,
    sideEnd: 7,
  }
  // backToTop = this.backToTop.bind(this)
  lock = false
  releaseTimer = NaN
  mouseTimer = null
  // add footer
  childrenLength = this.props.childrenLength + 1
  resizeHandler = null
  footerRef = null
  fullPageContainerRef = null
  get position() {
    if (typeof window === 'undefined') {
      return 0
    }
    if (
      this.state.activeSection >= this.childrenLength - 1 &&
      this.footerRef &&
      this.fullPageContainerRef
    ) {
      return this.state.windowHeight - this.fullPageContainerRef.clientHeight
    } else {
      return 0 - this.state.activeSection * this.state.windowHeight
    }
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    window.scroll(0, 0)
    this.resizeHandler = () => {
      this.setState({ windowHeight: window.innerHeight })
    }
    this.setState({ windowHeight: window.innerHeight })
    document.querySelector('body').style.overflow = 'hidden'
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('mousewheel', this.handleMouseWheel)
    window.addEventListener('DOMMouseScroll', this.handleMouseWheel)
    window.addEventListener('beforeunload', this.removeTimer)
    if (this.props.arrowNavigation) {
      window.addEventListener('keydown', this.handleArrowKeys)
    }
    if (this.props.touchNavigation) {
      this.handleTouchNav()
    }
    EventBus.addListener('backToTop', this.backToTop)
  }
  componentWillUnmount() {
    if (typeof window === 'undefined') {
      return
    }
    window.clearTimeout(this.releaseTimer)
    window.clearTimeout(this.mouseTimer)
    window.removeEventListener('resize', this.resizeHandler)
    window.removeEventListener('mousewheel', this.handleMouseWheel)
    window.removeEventListener('DOMMouseScroll', this.handleMouseWheel)
    window.removeEventListener('beforeunload', this.removeTimer)
    if (this.props.arrowNavigation) {
      window.removeEventListener('keydown', this.handleArrowKeys)
    }
    document.querySelector('body').style.overflow = 'initial'

    let touchsurface = this.fullPageContainerRef
    if (touchsurface) {
      touchsurface.removeEventListener('touchstart', this.handleTouchStart)
      touchsurface.removeEventListener('touchmove', this.handleTouchMove)
      touchsurface.removeEventListener('touchend', this.handleTouchEnd)
    }
    EventBus.removeListener('backToTop', this.backToTop)
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.activeSection !== nextProps.activeSection) {
      this.showTime(nextProps.activeSection)
    }
    if (this.props.childrenLength !== nextProps.childrenLength) {
      this.childrenLength = nextProps.childrenLength + 1
    }
  }
  removeTimer = () => {
    if (sessionStorage.getItem('beforeunload')) {
      sessionStorage.setItem('beforeunload', '')
      window.scroll(0, 0)
      // before leave page
      this.setState({
        activeSection: 0,
      })
      if (this.mouseTimer) {
        window.clearTimeout(this.mouseTimer)
      }
      if (this.releaseTimer) {
        window.clearTimeout(this.releaseTimer)
      }
    } else {
      sessionStorage.setItem('beforeunload', 'Y')
    }
  }
  handleTouchNav = () => {
    if (typeof window === 'undefined') {
      return
    }
    let touchsurface = this.fullPageContainerRef
    touchsurface.addEventListener('touchstart', this.handleTouchStart)
    touchsurface.addEventListener('touchmove', this.handleTouchMove)
    touchsurface.addEventListener('touchend', this.handleTouchEnd)
  }
  handleTouchStart = e => {
    let touchobj = e.changedTouches[0]
    this.startX = touchobj.pageX
    this.startY = touchobj.pageY
    this.startTime = new Date().getTime()
  }
  handleTouchMove = e => {
    e.preventDefault()
  }
  handleTouchEnd = e => {
    let threshold = 50
    let restraint = 100
    let allowedTime = 1000
    let touchobj = e.changedTouches[0]
    let distX = touchobj.pageX - this.startX
    let distY = touchobj.pageY - this.startY
    let elapsedTime = new Date().getTime() - this.startTime
    if (elapsedTime <= allowedTime) {
      if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) {
        if (this.lock) {
          return
        }
        this.lock = true
        let swipedir = distY < 0 ? 'up' : 'down'
        let direction =
          swipedir === 'down'
            ? this.state.activeSection - 1
            : swipedir === 'up'
            ? this.state.activeSection + 1
            : -1
        this.showTime(direction)
      }
    }
  }
  handleMouseWheel = event => {
    clearTimeout(this.mouseTimer)
    this.mouseTimer = window.setTimeout(() => {
      if (document.querySelector('body').style.overflow !== 'hidden') {
        document.querySelector('body').style.overflow = 'hidden'
        return
      }
      if (this.lock) {
        return
      }
      this.lock = true
      const e = window.event || event
      const delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail))
      this.showTime(this.state.activeSection - delta)
    }, 10)
  }
  handleArrowKeys = event => {
    if (this.lock) {
      return
    }
    this.lock = true
    const e = window.event || event
    const activeSection =
      e.keyCode === 38 || e.keyCode === 37
        ? this.state.activeSection - 1
        : e.keyCode === 40 || e.keyCode === 39
        ? this.state.activeSection + 1
        : -1
    this.showTime(activeSection)
  }
  showTime = activeSection => {
    if (activeSection < 0 || this.childrenLength === activeSection) {
      this.lock = false
      return
    } else if (activeSection < this.state.activeSection) {
      this.onPrevPage()
    } else {
      this.onNextPage()
    }
  }
  setFooterRef = ref => {
    // if ref on component can't get height :(
    this.footerRef = ref
  }
  setFullPageContainer = ref => {
    this.fullPageContainerRef = ref
  }
  onPrevPage = () => {
    const { sideStart, activeSection, sideEnd } = this.state
    if (activeSection === 0) {
      this.lock = false
      return
    }
    if (sideStart === activeSection) {
      this.setState({
        sideStart: sideStart - 1,
        sideEnd: sideEnd - 1,
        activeSection: activeSection - 1,
      })
    } else {
      this.setState({ activeSection: activeSection - 1 })
    }
    window.clearTimeout(this.releaseTimer)
    this.releaseTimer = window.setTimeout(() => {
      this.lock = false
    }, this.props.delay + 300)
  }
  onNextPage = () => {
    const { sideStart, activeSection, sideEnd } = this.state
    if (activeSection === 12) {
      this.lock = false
      return
    }
    if (sideEnd === activeSection) {
      this.setState({
        sideStart: sideStart + 1,
        sideEnd: sideEnd + 1,
        activeSection: activeSection + 1,
      })
    } else {
      this.setState({ activeSection: activeSection + 1 })
    }
    window.clearTimeout(this.releaseTimer)
    this.releaseTimer = window.setTimeout(() => {
      this.lock = false
    }, this.props.delay + 300)
  }
  render() {
    const { children, footerInfo, caseArray } = this.props
    const { activeSection } = this.state
    // init array [0, 1, 2, ..., this.childrenLength - 1]
    const containerStyle = () => ({
      height: '100%',
      width: '100%',
      position: 'relative',
      transform: `translate3d(0px, ${this.position}px, 0px)`,
      transition: `transform ${this.props.delay}ms ease`,
    })
    return (
      <React.Fragment>
        <div ref={this.setFullPageContainer} style={containerStyle()}>
          {children}
          <div ref={this.setFooterRef}>
            <MoreCaseContainer>
              <H2>瑞云渲染客户如繁星般璀璨</H2>
              <Link to='/vfx.html'>
                <Button backgroundColor='white' color='primary'>
                  更多案例
                </Button>
              </Link>
            </MoreCaseContainer>
            <Footer info={footerInfo} />
          </div>
        </div>
        <CaseNav>
          <NavDirection onClick={this.onPrevPage} disabled={activeSection === 0}>
            <svg xmlns='http://www.w3.org/2000/svg'>
              <path
                fill-rule='evenodd'
                strokeWidth='2px'
                strokeLinecap='round'
                strokeLinejoin='miter'
                fill='none'
                d='M1.999,10.998 L9.999,2.999 L17.999,10.998 '
              />
            </svg>
          </NavDirection>
          {[null, ...caseArray, null]
            .slice(this.state.sideStart, this.state.sideEnd + 1)
            .map((caseData, index) => {
              const { sideStart } = this.state
              return (
                <CaseNavSpot
                  key={sideStart + index}
                  isActive={activeSection === sideStart + index}
                  onClick={() => this.setState({ activeSection: sideStart + index })}
                >
                  {sideStart + index + 1 < 10 ? `0${sideStart + index + 1}` : sideStart + index + 1}
                  {caseData && (
                    <CaseNavTitle
                      className='case-nav-title'
                      isActive={activeSection === sideStart + index}
                    >
                      {caseData.title}
                    </CaseNavTitle>
                  )}
                </CaseNavSpot>
              )
            })}
          <NavDirection onClick={this.onNextPage} disabled={activeSection === 12}>
            <svg xmlns='http://www.w3.org/2000/svg'>
              <path
                fillRule='evenodd'
                strokeWidth='2px'
                strokeLinecap='round'
                strokeLinejoin='miter'
                fill='none'
                d='M17.999,2.593 L9.999,10.592 L1.999,2.593 '
              />
            </svg>
          </NavDirection>
        </CaseNav>
      </React.Fragment>
    )
  }
}

FullPage.defaultProps = {
  delay: 1000,
  navigation: true,
  activeSection: 0,
  arrowNavigation: true,
  touchNavigation: true,
  childrenLength: 0,
}

FullPage.propTypes = {
  delay: PropTypes.number,
  navigation: PropTypes.bool,
  arrowNavigation: PropTypes.bool,
  touchNavigation: PropTypes.bool,
  activeSection: PropTypes.number,
  childrenLength: PropTypes.number,
}

export default FullPage
