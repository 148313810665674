import styled from 'styled-components'
import { H2 } from '@renderbus/common/components'
import Media from '@renderbus/common/theme/media'

export const CaseContainer = styled.div`
  position: relative;
  height: ${p => (p.windowHeight ? p.windowHeight : '100vh')};
  color: white;
`
export const CaseContent = styled.div`
  position: absolute;
  top: 0;
  right: 20%;
  padding: 100px 20px 0;
  min-height: 50vh;
  width: 390px;
  background: rgba(0, 0, 0, 0.7);
  ${Media.lessThan(Media.small)} {
    width: 80%;
    right: 50%;
    transform: translateX(50%);
  }
`
export const CaseTitle = styled(H2)`
  margin-bottom: 30px;
`
export const CaseReward = styled.p`
  margin: 0;
`
