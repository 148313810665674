class caseDatas {
  constructor(title = '', info = '', background, bgMobile, rewards = []) {
    this.title = title
    this.rewards = rewards
    this.info = info
    this.background = background
    this.bgMobile = bgMobile
  }
}
export default caseDatas
